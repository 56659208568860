@import "../../../../variables.scss";

.TextArea {
  font-size: $fontSize2;
  padding: 10px 13px;
  outline: none;
  background-color: white;
  color: $black1;
  border: 1px solid transparent;
  resize: vertical;
}

.TextArea::placeholder {
  color: $gray2;
}

.TextArea:focus {
  border: 1px solid $red4;
}
