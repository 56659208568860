@import "../../../../variables.scss";

.hamburgerIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.15s;
    position: relative;
    overflow: hidden;
}

.hamburgerIconContainer:hover {
    background-color: $red5;
}

.normalHamburgerLine1,
.normalHamburgerLine2,
.normalHamburgerLine3,
.xHamburgerLine1,
.xHamburgerLine2,
.xHamburgerLine3 {
    position: absolute;
    width: 25px;
    height: 4px;
    background-color: $red1;
    border-radius: 5px;
    transition-timing-function: ease-in-out;
    opacity: 1;
    display: flex;
}

.normalHamburgerLine1,
.normalHamburgerLine3,
.xHamburgerLine1,
.xHamburgerLine3 {
    transition: 0.5s;
}

.normalHamburgerLine2,
.xHamburgerLine2 {
    transition: 0.4s;
}

.normalHamburgerLine1 {
    transform: translateY(-8px);
}

.normalHamburgerLine3 {
    transform: translateY(8px);
}

.xHamburgerLine1 {
    transform: rotate(135deg);
}

.xHamburgerLine2 {
    transform: translateX(-50px);
    opacity: 0;
}

.xHamburgerLine3 {
    transform: rotate(45deg);
}
