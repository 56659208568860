@import "../../../../../variables.scss";

.FooterSection {
  width: 100%;
  background-color: $gray2;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  font-weight: 300;
}

.FooterSection p {
  margin: 5px;
}
