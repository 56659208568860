@import "../../../../variables.scss";

.DesktopNavigationContainer {
	width: 100%;
}

.DesktopNavigation {
	width: 80%;
	max-width: 1200px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.DesktopNavigation img {
	height: 25px;
	cursor: pointer;
}

.buttonsContainer {
	margin-right: -10px;
}

.buttonsContainer button {
	font-size: 16px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	transition: $transition1;
	height: 30px;
	padding: 0 10px;
	border-radius: 3px;
}

.buttonsContainer button:hover {
	background-color: $red5;
}

.buttonsContainer button:first-child {
	margin-right: 15px;
}
