@import "../../../../../variables.scss";

.AboutCompanySection {
  //padding: $sectionPadding;
  width: 80%;
  margin: 0 auto;
  background-color: white;
}

.AboutCompanySection p {
  margin: 0;
}

.initInfo {
  text-align: center;
  width: 100%;
  margin: 60px auto;
  margin-bottom: 70px;
}

.imageContainer {
  width: 100%;
  height: 210px;
  margin-top: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.infoContainer img {
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

// .infoContainer img:hover {
//   transform: scale(1.07);
// }

.infoContainer {
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: column;
}

.infoContainer:not(:first-child) {
  margin-top: 40px;
}

.infoContainer div {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.infoContainer h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

@media screen and (min-width: 800px) {
  .infoContainer {
    flex-direction: row;
  }

  .infoContainer:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .imageContainer {
    max-width: 240px;
    height: 210px;
    margin-top: 0;
    overflow: initial;
  }

  .infoContainer img {
    border-radius: 50%;
  }

  // .infoContainer img:hover {
  //   transform: none;
  // }

  .infoContainer:nth-child(even) .imageContainer {
    margin-right: 20px;
  }

  .infoContainer:nth-child(odd) .imageContainer {
    margin-left: 20px;
  }
}

@media screen and (min-width: 800px) {
  .initInfo {
    width: 75%;
  }
}

@media screen and (min-width: 1200px) {
  .AboutCompanySection {
    max-width: 1200px;
  }

  .infoContainer:not(:first-child) {
    margin-top: 25px;
  }
}
