@import "../../../../variables.scss";

.FileUploadContainer {
  margin-top: 10px;
}

.FileUpload {
  background-color: #333333;
  width: 120px;
  height: 40px;
  color: white;
  line-height: 40px;
  float: left;
  text-align: center;
  margin-right: 20px;
}

.ImagePreview {
  height: 80px;
  width: 80px;
  object-fit: cover;
  float: left;
}

.ImagePreviewContainer {
  height: 80px;
  width: 80px;
  float: left;
  position: relative;
}

.RemoveImage {
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 0px;
  right: 0px;
  background-color: rgba($color: #000000, $alpha: 0.7);
}

.Cross {
  position: absolute;
  height: 20px;
  width: 20px;
  margin: 5px;
  bottom: 0px;
  right: 0px;
}
