@import "src/variables";

.AdminPage {
	margin-top: calc(54px + 20px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0 calc(5% - 7px) 0 5%;
}

.AdminPage form {
	display: flex;
	flex-direction: column;
	height: 150px;
	justify-content: space-between;
}

.AdminPage p {
	color: $red1;
	margin-top: 10px;
	text-align: center;
}

.AdminPage form,
.AdminPage p {
	width: 100%;
	max-width: 350px;
}
