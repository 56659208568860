@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600&display=swap");

@import "./variables.scss";

* {
  font-family: "Catamaran", sans-serif;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  margin: 0;
  background-color: white;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  //font-weight: 300;
  line-height: 27px;
}

h1 {
  font-size: $fontSize5;
  margin: 10px 0 5px;
  font-weight: 500;
  line-height: 42px;
}
h2 {
  font-size: $fontSize4;
  margin: 10px 0 5px;
  font-weight: 300;
  line-height: 33px;
}

h3 {
  font-size: $fontSize4;
  margin: 10px 0 5px;
  font-weight: 300;
  line-height: 30px;
}

h4 {
  font-size: $fontSize1;
  margin: 10px 0 5px;
  font-weight: 500;
}
