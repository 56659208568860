@import "../../../../../variables.scss";

.ContactFormSection {
  background-color: $gray5;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  //padding: $sectionPadding;
}

.ContactFormContent {
  max-width: 1200px;
  width: 80%;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}

.contactForm {
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.contactForm textarea {
  height: 200px;
  min-height: 100px;
}

.contactForm input,
.contactForm textarea {
  width: 100%;
}

.contactForm input:not(:first-child),
.contactForm textarea:not(:first-child),
.contactForm .FileUploadContainer {
  margin-top: 10px;
}

.contactForm button {
  margin-top: 10px;
}

.contactAndInfo {
  margin-bottom: 20px;
}

.contactAndInfo h2 {
  margin-top: 0;
  font-weight: 700;
}

.alert {
  color: red;
  font-weight: 700;
}

@media screen and (min-width: 740px) {
  .ContactFormSection,
  .ContactFormContent {
    flex-direction: row;
    height: fit-content;
  }

  .contactForm {
    width: 50%;
  }

  .contactAndInfo {
    width: 50%;
    margin-left: 50px;
    margin-bottom: 0;
  }

  .contactForm textarea {
    height: calc(100% - 173px);
    //height: calc(100% - 228px);
  }

  .ContactFormContent {
  }
}
