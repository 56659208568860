// --- FONTS ---
$fontSize1: 12px;
$fontSize2: 15px;
$fontSize3: 20px;
$fontSize4: 25px;
$fontSize5: 30px;

// --- COLORS ---
$black1: #232323;

$gray1: #333333;
$gray2: #666666;
$gray3: #999999;
$gray4: #c8c8c8;
$gray5: #e6e6e6;
$gray6: #f2f2f2;

$blue1: #1954a6;
$blue2: #4776b8;
$blue3: #7598ca;
$blue4: #a3bbdb;
$blue5: #d1dded;

$red1: #ff0000;
$red2: #ff3333;
$red3: #ff6666;
$red4: #ff9999;
$red5: #ffcccc;
$red6: #ffe5e5;
$red7: #fff2f2;

// --- OTHER ---
$transition1: 0.2s;
$transition2: 0.4s;
$transition3: 0.6s;
$transition4: 0.8s;
$transition5: 1s;

$sectionPadding: 30px 5%;
