@import "../../../../variables.scss";

.SubmitButton {
  border: none;
  cursor: pointer;
  font-size: $fontSize2;
  transition: $transition1;
  background-color: $red2;
  color: white;
  height: 40px;
}

.SubmitButton:hover {
  background-color: $red3;
}
