@import "../../../../variables.scss";

.MobileNavigation {
  width: 100%;
  background-color: white;
  //border-bottom: 4px solid $red1;
  height: 50px;
  padding: 0 calc(5% - 7px) 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 30px;
  z-index: 1000;
  box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.2);
}

.MobileNavigation img {
  height: 25px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 75px;
  width: 100%;
  height: fit-content;
  transition: $transition3;
  background-color: white;
  z-index: 200;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.hideModal {
  transform: translateY(-100%);
}

.modalBackground {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: $transition3;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.65);
  //backdrop-filter: blur(2px);
  z-index: 100;
  cursor: pointer;
}

.hideModalBackground {
  opacity: 0;
  z-index: -1;
}

.mobileNavBtn {
  cursor: pointer;
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  border: none;
  transition: $transition1;
  height: 30px;
  padding: 0 10px;
  border-radius: 3px;
  width: fit-content;
  font-size: 16px;
}

.mobileNavBtn:hover {
  background-color: $red5;
}

.mobileNavBtn:first-child {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.mobileNavBtn:last-child {
  margin-left: 10px;
  margin-bottom: 10px;
}

@media (min-width: 650px) {
  .buttonsContainer {
    display: flex;
  }

  .showMenuModal {
    display: none;
  }
}
