@import "../../../../../variables.scss";

.SuppliersPage {
  padding: $sectionPadding;
  background-color: white;
  min-height: 150px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.SuppliersPage h2 {
  margin-bottom: 30px;
}

.logosContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -30px;
}

.largeLogoContainer1,
.largeLogoContainer2 {
  flex: 0 0 100%;
  margin: 20px;
}

.largeLogoContainer1 img {
  height: 90px;
}

.largeLogoContainer2 img {
  height: 50px;
}

.logoContainer {
  margin: 20px;
  display: flex;
  align-items: center;
}

.logoContainer img {
  height: 38px;
  filter: grayscale(100%);
  transition: $transition1;
}

.logoContainer img:hover {
  filter: grayscale(0);
}
