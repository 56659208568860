@import "../../../../../variables.scss";

.SuppliersPage {
  padding: $sectionPadding;
  background-color: white;
  min-height: 150px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.SuppliersPage h2 {
  margin-bottom: 30px;
}

.logosContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -30px;
}

.logoContainer {
  margin: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logoContainer img {
  height: 200px;
  border-radius: 100%;
  // filter: grayscale(100%);
  transition: $transition1;
}

.coworkerName {
  font-size: large;
}

.coworkerTitle {
  font-size: medium;
}

// .logoContainer img:hover {
//   filter: grayscale(0);
// }
