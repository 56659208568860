@import "../../../../variables.scss";

.TextInputField {
  height: 40px;
  font-size: $fontSize2;
  padding: 0 13px;
  outline: none;
  background-color: white;
  color: $black1;
  border: 1px solid transparent;
}

.TextInputField::placeholder {
  color: $gray2;
}

.TextInputField:focus {
  border: 1px solid $red4;
}
