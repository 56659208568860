@import "../../../../../variables.scss";

.WelcomeCanvasSection {
  width: 100%;
  height: 67vh;
  min-height: 500px;
  overflow: hidden;
}

.Image {
  height: 100%;
  width: 100%;
  min-width: 700px;
  object-fit: cover;
}

.ImageText {
  position: absolute;
  display: block;
  text-wrap: nowrap;
  text-align: right;
  line-height: normal;
  top: 60%;
  right: 0px;
  left: 5%;
  font-size: 2em;
  color: white;
}

@media screen and (min-width: 360px) {
  .ImageText {
    top: 120px;
    right: 10%;
    font-size: 1.4em;
    left: auto;
  }
}

@media screen and (min-width: 500px) {
  .ImageText {
    font-size: 2em;
  }
}

@media screen and (min-width: 1300px) {
  .ImageText {
    top: 120px;

    right: 10%;
    font-size: 3em;
    left: auto;
  }
}
