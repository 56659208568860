@import "./src/variables";

.HomePage {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

@media screen and (min-width: 650px) {
  .HomePage {
  }
}
