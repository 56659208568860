@import "../../../variables.scss";

.Navigation {
}

.desktopContainer {
  z-index: 1000;
  display: none;
}

.infoBar {
  position: fixed;
  top: 0;
  height: 30px;
  background-color: $red2;
  width: 100%;
  text-align: center;
  z-index: 100000;
}

.infoBar p {
  margin: 0;
  margin-top: 2px;
  color: white;
}

.infoBar p a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.mobileContainer {
  top: 30px;
  display: block;
  height: 50px;
  display: flex;
}

@media screen and (min-width: 650px) {
  .desktopContainer {
    display: block;
    width: 100%;
    position: fixed;
    top: 30px;
    height: 50px;
    z-index: 1000;
    -webkit-appearance: none;
    //border-bottom: 4px solid $red1;
    box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.9);
    -webkit-box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    display: flex;
    justify-content: center;
  }

  .mobileContainer {
    display: none;
  }
}
